body {
  font-family: var(--fontStack);
  font-weight: var(--fontWeightRegular);
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  background: rgb(var(--rgbBackground));
  color: var(--colorTextBody);
  border: 0;
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
  cursor: none;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

::selection {
  background: rgb(var(--rgbAccent));
  color: rgb(var(--rgbBlack));
}

.cursor {
  pointer-events: none;
  @media (max-width: 696px) {
    & {
      display: none;
    }
  }
}
 .cursor_ball {
  position: fixed;
  top: 0;
  left: 0;
  mix-blend-mode: difference;
  z-index: 1000;
}

 .cursor_ball circle {
  fill: #f7f8fa;
}


@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.app {
  width: 100%;
  position: relative;
  background: rgb(var(--rgbBackground));
  transition: background var(--durationM) ease;
  outline: none;
  display: grid;
  grid-template: 100% / 100%;
}

.app_page {
  opacity: 0;
  grid-area: 1 / 1;
  transition: opacity var(--durationS) ease;
  min-height: 100vh;
}

.app_page--exiting,
.app_page--entering {
  opacity: 0;
}

.app_page--entered {
  transition-duration: var(--durationL);
  transition-delay: var(--durationXS);
  opacity: 1;
}
